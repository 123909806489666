<template>
  <div>
    <a-modal
      title="分析结果"
      :visible="visible"
      :centered="true"
      width="70%"
      :footer="null"
      @cancel="handleCancel"
    >
      <vue-scroll :ops="$root.scrollOpsY" style="height:200px">
        <a-table
          :columns="columns"
          :rowKey="(record, index) => index"
          :components="resibleTableHeader"
          :data-source="dataList"
          :pagination="paginationOpt"
          :loading="loading"
          :scroll="scroll"
          bordered
        >
          <!-- 操作按钮 -->

          <template slot="action" slot-scope="text, record">
            <span class="pointer m-r-sm" @click="viewResult(record)"
              >查看结果</span
            >
            <span class="pointer" @click="download" v-show="false"
              >下载结果</span
            >
          </template>
        </a-table>
      </vue-scroll>
    </a-modal>
    <analyze-count-result
      :taskId="taskId"
      :visible="resultVisble"
      @close="closeHandle"
    />
  </div>
</template>

<script>
import analyzeCountResult from './analyzeCountResult';
import { CODE_OK } from '@/request/config_code';
import ResizableTable from '@/components/mixins/resizeableTable';
export default {
  name: 'analyzeCount',
  mixins:[ResizableTable],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    planId: {
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      columns: [
        //一个对象是一列
        {
          title: '序号',
          dataIndex: 'eventIndex',
          customRender: (text, record, index) => index + 1,
          width: 60,
        },
        {
          title: '检测完成时间',
          dataIndex: 'endDate',
          key: 'endDate',
          ellipsis: true,
          /* customRender: (text, record) => {
            return record.beginDate && record.endDate
              ? `${record.beginDate}~${record.endDate}`
              : '--';
          }, */
          width: 140,
        },
        {
          title: '分析事件',
          dataIndex: 'eventNameStr',
          key: 'eventNameStr',
          ellipsis: true,
          width:200
        },
        {
          title: '分析摄像机数',
          dataIndex: 'cameraCount',
          key: 'cameraCount',
          ellipsis: true,
          width: 140,
        },
        {
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          width: 100,
          scopedSlots: {
            customRender: 'action',
          },
        },
      ],
      dataList: [],
      paginationOpt: {
        current: 1, // 默认当前页数
        defaultPageSize: 10, // 默认当前页显示数据的大小
        total: 10, // 总数，必须先有
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['5', '10', '15', '20'],
        showTotal: (total) => `共 ${total} 条`, // 显示总数
        onShowSizeChange: (current, pageSize) => {
          this.paginationOpt.current = 1;
          this.paginationOpt.defaultPageSize = pageSize;
          this._getAnalyseExecuteTaskList();
          // this.resetSelect();
          // this._getTurnList();
        },
        // 改变每页数量时更新显示
        onChange: (current, size) => {
          this.paginationOpt.current = current;
          this.paginationOpt.defaultPageSize = size;
          this._getAnalyseExecuteTaskList();
        },
      },
      resultVisble: false,
      taskId: '',
      loading: false,
      scroll:{x:'100%'}
    };
  },
  computed: {},
  watch: {
    planId(nv, ov) {
      if (nv && nv !== ov) {
        this._getAnalyseExecuteTaskList();
      }
    },
  },
  components: {
    analyzeCountResult,
  },
  methods: {
    _getAnalyseExecuteTaskList() {
      let params = {
        currPage: this.paginationOpt.current,
        pageSize: this.paginationOpt.defaultPageSize,
        planId: this.planId,
      };
      this.loading = true;
      this.$api.aiCenterY.getAnalyseExecuteTaskList(params).then((res) => {
        if (res.code === CODE_OK) {
          this.dataList = res.data;
          this.paginationOpt.total = res.total;
        } else {
          this.$message.error('获取数据失败!');
        }
        this.loading = false;
      });
    },
    handleCancel() {
      this.$emit('close');
    },
    viewResult(record) {
      this.resultVisble = true;
      this.taskId = record.taskId;
      // console.error(record);
    },
    download() {},
    closeHandle() {
      this.resultVisble = false;
      this.taskId = '';
    },
  },
};
</script>

<style scoped></style>
