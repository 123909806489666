<template>
  <div class="text-lightblue">
    <span class="pointer" @click="goBack()">
      <a-icon type="arrow-left" class="m-r-xs" />退出
    </span>
  </div>
</template>

<script>
export default {
  name: 'Back',
  props: {
    url: {
      type: String,
      default: '',
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {
    goBack() {
      if (!this.url) {
        this.$router.go(-1);
        return;
      }
      this.$router.push({ path: this.url });
    },
  },
};
</script>

<style scoped></style>
