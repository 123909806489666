<template>
  <div class="full-width full-height bg-blue p-sm">
    <!-- 退出按钮插件 -->
    <back url="/ai-center" />
    <!-- 搜索框一栏 -->
    <div class="flex m-t-sm justify-between  m-b-sm">
      <div class="flex flex-one flex-wrap items-center text-lightblue">
        <span class="flex-s m-r-xs  m-b-sm">启用状态：</span>
        <a-select
          placeholder="启用状态"
          class="m-r-sm search-mx-wd-1 m-b-sm"
          v-model="searchParams.status"
          @change="handleChangeStatus"
        >
          <a-select-option :value="1">启用</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
        <span class="flex-s m-r-xs  m-b-sm">分析周期：</span>
        <a-select
          placeholder="请选择分析周期"
          class="m-r-sm search-mx-wd-1 m-b-sm"
          v-model="searchParams.schemaId"
          @change="handleChangeSchema"
        >
          <a-select-option
            v-for="item in schemaList"
            :key="item.id"
            :value="item.value"
            >{{ item.name }}</a-select-option
          >
        </a-select>
        <div class="flex items-center search-date-picker-tit m-r-sm  m-b-sm">
          <span class="flex-s m-r-xs">创建时间：</span>
          <a-range-picker
            v-model="searchParams.dataPicker"
            @change="onChangeDate"
            class="flex-one"
            :showTime="{
              defaultValue: [defaultStart, defaultEnd],
            }"
          >
          </a-range-picker>
        </div>
        <div class="flex items-center search-mx-wd-2 m-r-sm">
          <span class="flex-s m-r-xs m-b-sm">预案名称：</span>
          <a-input
            placeholder="方案名称"
            v-model="searchParams.schemaName"
            class="m-r-sm m-b-sm"
          />
        </div>
        <!-- <span class="flex-s m-r-xs  m-b-sm">事件类型：</span>
        <a-select
          placeholder="选择事件类型"
          class="m-r-sm search-mx-wd-1  m-b-sm"
          v-model="searchParams.eventTypeId"
          @change="handleChangeType"
        >
          <a-select-option
            v-for="item in eventTypeList"
            :key="item.id"
            :value="item.value"
            >{{ item.name }}</a-select-option
          >
        </a-select> -->

        <!-- 时间 -->

        <!-- 搜索按钮 -->
        <a-button
          type="primary"
          class="m-r-sm btn-search  m-b-sm"
          @click="searchHandle"
        >
          搜索
        </a-button>
        <!-- 重置按钮 -->
        <a-button class="btn-reset  m-b-sm" @click="resetHandle">
          重置
        </a-button>
      </div>
      <div class="flex">
        <span class="span-line m-l-md m-r-md"></span>
        <a-button class="btn-export m-r-sm" @click="addHandle">
          新增
        </a-button>
        <a-button class="btn-export" @click="exportHandle">
          数据导出
        </a-button>
      </div>
    </div>
    <!-- 表格 -->

    <a-table
      :columns="columns"
      :rowKey="(record, index) => index"
      :components="resibleTableHeader"
      :data-source="analyzeList"
      :pagination="paginationOpt"
      :scroll="scroll"
      :loading="loading"
      bordered
    >
      <!-- 操作按钮 -->
      <div
        slot="filterDropdown"
        slot-scope=""
        class="p-md"
        style="width:300px;"
      >
        <a-checkbox-group
          class="full-width"
          :value="checkboxValue"
          @change="(e) => onChangeFilter(e)"
        >
          <a-row>
            <a-col :span="12" v-for="item in filterColumns" :key="item.id">
              <a-checkbox :value="item.value" v-if="item.value != 8">
                {{ item.text }}
              </a-checkbox>
            </a-col>
          </a-row>
        </a-checkbox-group>

        <span class="flex justify-center"
          ><a-button type="primary" class="no-margin" @click="saveFilterHandle"
            >保存</a-button
          ></span
        >
      </div>
      <template slot="customStatus" slot-scope="text, record">
        <a-switch
          :checked="getCheck(text)"
          @change="(e) => toggleAction(e, record)"
          class="m-r-sm"
        />
      </template>
      <template slot="action" slot-scope="text, record">
        <!-- √ -->
        <!--  <button class="plain-btn pointer m-r-sm" @click="toggleAction(record)">
          <a-icon type="minus-circle" v-show="record.schemeStatus === 0" />
          <a-icon type="check-circle" v-show="record.schemeStatus === 1" />
        </button> -->
        <!-- 编辑 -->
        <button
          class="plain-btn pointer m-r-sm"
          v-show="false"
          @click="editHandle(record)"
        >
          <a-icon type="form" />
        </button>
        <!-- 删除 -->
        <button class="plain-btn pointer m-r-sm" @click="delHandle(record)">
          <a-icon type="rest" />
        </button>
      </template>
      <!--  <template slot="analyseType" slot-scope="text">
        <span>{{ text | getAnalyseType }}</span>
      </template> -->
      <template slot="analyzeNum" slot-scope="text, record">
        <span class="pointer text-orange" @click="viewHandle(record)">{{
          text
        }}</span>
      </template>
    </a-table>

    <analyze-count
      :visible="visibleCount"
      :planId="planId"
      @close="closeHandle"
    />
    <top-right-analyzed-dialog
      :isEdit="isEdit"
      :editData="editData"
      :visible="addVisible"
      @close="closeAnalyzed"
      @sure="sureHandle"
    />
  </div>
</template>

<script>
import Back from '@/components/Back';
import AnalyzeCount from './components/analyzeCount';
import TopRightAnalyzedDialog from '@/components/TopRightAnalyzedDialog';
import ResizableTable from '@/components/mixins/resizeableTable';
import { handleExportFile } from '@/tools';
import { mapActions, mapState } from 'vuex';
import moment from 'moment';
import { CODE_OK } from '@/request/config_code';
export default {
  name: 'AiAnalyzed',
  mixins:[ ResizableTable ],
  data() {
    return {
      columns: [
        //一个对象是一列
        {
          title: '序号',
          dataIndex:'planIndex',
          customRender: (text, record, index) => index + 1,
          width: 60,
          customId: 0,
        },
        {
          title: '预案名称',
          dataIndex: 'planName',
          key: 'planName',
          ellipsis: true,
          customId: 1,
          width: 140,
        },
        {
          title: '分析事件',
          dataIndex: 'eventNameStr',
          key: 'eventNameStr',
          ellipsis: true,
          customId: 2,
          width:220
        },
        {
          title: '分析周期',
          dataIndex: 'analyseType',
          key: 'analyseType',
          ellipsis: true,
          customId: 3,
          customRender: (text) => {
            return text === 0
              ? '立即循环分析'
              : text === 1
              ? '按时间段定时巡检'
              : '每日定时巡检';
          },
          width: 120,
        },

        /*   {
          title: '分析方案',
          dataIndex: 'analyzeSchema',
          key: 'analyzeSchema',
          ellipsis: true,
          customId: 4,
        }, */
        {
          title: '执行时间',
          dataIndex: 'executeDate',
          key: 'executeDate',
          ellipsis: true,
          customId: 4,
          width: 200,
        },
        {
          title: '摄像机数',
          dataIndex: 'cameraCount',
          key: 'cameraCount',
          ellipsis: true,
          width: 80,
          customId: 5,
        },
        /*  {
          title: '摄像机组数',
          dataIndex: 'cameraGroupNum',
          key: 'cameraGroupNum',
          ellipsis: true,
          customId: 7,
          // scopedSlots: { customRender: 'cameraStatus' },
        }, */
        {
          title: '创建人',
          dataIndex: 'userName',
          key: 'userName',
          ellipsis: true,
          width: 100,
          customId: 6,
        },
        {
          title: '创建时间',
          dataIndex: 'gmtCreate',
          key: 'gmtCreate',
          ellipsis: true,
          customId: 7,
          width: 160,
          customRender: (text) => {
            return text ? text : '--';
          },
        },
        /* {
          title: '上次编辑时间',
          dataIndex: 'lastEditTime',
          key: 'lastEditTime',
          ellipsis: true,
          customId: 8,
        }, */
        {
          title: '分析次数及结果',
          dataIndex: 'executeCount',
          key: 'executeCount',
          ellipsis: true,
          width: 120,
          scopedSlots: { customRender: 'analyzeNum' },
          customId: 8,
        },
        {
          title: '是否启用',
          dataIndex: 'planStatus',
          key: 'planStatus',
          ellipsis: true,
          width: 90,
          scopedSlots: { customRender: 'customStatus' },
          /*  customRender: (text) => {
            return text === 0 ? '停用' : '启用';
          }, */
          customId: 9,
        },
        {
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          width: 60,
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            customRender: 'action',
          },
          customId: 10,
          className:'column-center'
          // 操作符号
        },
      ],
      paginationOpt: {
        current: 1, // 默认当前页数
        defaultPageSize: 10, // 默认当前页显示数据的大小
        total: 10, // 总数，必须先有
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['5', '10', '15', '20'],
        showTotal: (total) => `共 ${total} 条`, // 显示总数
        onShowSizeChange: (current, pageSize) => {
          this.paginationOpt.current = 1;
          this.paginationOpt.defaultPageSize = pageSize;
          this._getAnalyze();
          // this.resetSelect();
          // this._getTurnList();
        },
        // 改变每页数量时更新显示
        onChange: (current, size) => {
          this.paginationOpt.current = current;
          this.paginationOpt.defaultPageSize = size;
          this._getAnalyze();
        },
      },

      filterColumns: [
        {
          id: 6,
          value: 6,
          text: '创建人',
          check: false,
        },
        {
          id: 7,
          value: 7,
          text: '创建时间',
          check: false,
        },
        {
          id: 8,
          value: 8,
          text: '上次编辑时间',
          check: false,
        },
      ],
      checkboxValue: [],
      visibleCount: false,
      analyzeList: [],
      addVisible: false,
      editData: {},
      isEdit: false,
      searchParams: {
        schemaName: '',
        eventTypeId: undefined,
        schemaId: undefined,
        status: undefined,
        dataPicker: null,
      },
      eventTypeList: [
        {
          id: 0,
          value: 0,
          name: '截图',
        },
        {
          id: 1,
          value: 1,
          name: '录像',
        },
      ],
      schemaList: [
        {
          id: 1,
          value: 1,
          name: '按时间段定时巡检',
        },
        {
          id: 2,
          value: 2,
          name: '每日定时巡检',
        },
        {
          id: 0,
          value: 0,
          name: '立即循环分析',
        },
        /*  {
          id: 3,
          value: 3,
          name: '按路数优先分析',
        }, */
      ],
      scroll: { y: window.innerHeight - 350,x:'100%' },
      planId: '',
      loading: false,
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.login.userInfo,
    }),
    defaultStart() {
      return moment('00:00:00', 'HH:mm:ss');
    },
    defaultEnd() {
      return moment('23:59:59', 'HH:mm:ss');
    },
  },
  watch: {},
  components: {
    Back,
    AnalyzeCount,
    TopRightAnalyzedDialog,
  },
  created() {
    /*    this.checkboxValue = this.filterColumns
      .filter((item) => item.check)
      .map((item) => item.value);*/
    this.copyColums = this.columns.slice();
    this.setChecked();
    //拉取数据
    this._getAnalyze();
    //获取分组
    this.getCameraGroupCondition();
  },
  mounted() {},
  methods: {
    getCheck(text) {
      return text === 1 ? true : false;
    },

    saveFilterHandle() {
      this.setChecked();
    },

    setChecked() {
      let checkValues = [0, 1, 2, 3, 4, 5, /*6, 7, */ 8, 9, 10];
      checkValues = checkValues.concat(this.checkboxValue);
      let columns = this.copyColums.filter((item) =>
        checkValues.includes(item.customId)
      );
      this.columns = columns;
    },
    _getAnalyze() {
      /* let params = {
        currPage: this.paginationOpt.current,
        pageSize: this.paginationOpt.defaultPageSize,
        schemeName: this.searchParams.schemaName,
        analyseType: this.searchParams.schemaId,
        schemeStatus: this.searchParams.status,
        beginDate: this.searchParams.dataPicker
          ? moment(this.searchParams.dataPicker[0]).format(
              'YYYY-MM-DD HH:mm:ss'
            )
          : '',
        endDate: this.searchParams.dataPicker
          ? moment(this.searchParams.dataPicker[1]).format(
              'YYYY-MM-DD HH:mm:ss'
            )
          : '',
      }; */
      let params = {
        analyseType: this.searchParams.schemaId,
        beginDate: this.searchParams.dataPicker
          ? moment(this.searchParams.dataPicker[0]).format(
              'YYYY-MM-DD HH:mm:ss'
            )
          : '',
        currPage: this.paginationOpt.current,
        endDate: this.searchParams.dataPicker
          ? moment(this.searchParams.dataPicker[1]).format(
              'YYYY-MM-DD HH:mm:ss'
            )
          : '',
        pageSize: this.paginationOpt.defaultPageSize,
        planName: this.searchParams.schemaName,
        planStatus: this.searchParams.status,
      };
      this.loading = true;
      this.$api.aiCenterY.getAnalysePLanList(params).then((res) => {
        if (res.code === CODE_OK) {
          this.analyzeList = res.data;
          this.paginationOpt.total = res.total;
        } else {
          this.$message.error('拉取列表失败');
        }
        this.loading = false;
      });
    },
    handleChangeType(e) {
      this.searchParams.eventTypeId = e;
    },
    handleChangeSchema(e) {
      this.searchParams.schemaId = e;
    },

    onChangeDate(dataPicker) {
      this.searchParams.dataPicker = dataPicker.length > 0 ? dataPicker : null;
    },
    searchHandle() {
      this.paginationOpt.current = 1;
      this.paginationOpt.defaultPageSize = 10;
      this._getAnalyze();
    },
    resetHandle() {
      // 重置按钮，输入框的内容为空
      this.searchParams.schemaName = '';
      // this.searchParams.eventTypeId = undefined;
      this.searchParams.schemaId = undefined;
      this.searchParams.status = undefined;
      this.searchParams.dataPicker = null;
      this._getAnalyze();
    },
    exportHandle() {
      if(!this.hasPermission(1092121315)) {
        this.permissionWarning();
        return;
      }
      let params = {
        analyseType: this.searchParams.schemaId,
        beginDate: this.searchParams.dataPicker
          ? moment(this.searchParams.dataPicker[0]).format(
              'YYYY-MM-DD HH:mm:ss'
            )
          : '',
        currPage: this.paginationOpt.current,
        endDate: this.searchParams.dataPicker
          ? moment(this.searchParams.dataPicker[1]).format(
              'YYYY-MM-DD HH:mm:ss'
            )
          : '',
        pageSize: this.paginationOpt.defaultPageSize,
        planName: this.searchParams.schemaName,
        planStatus: this.searchParams.status,
      };
      this.$api.aiCenterY.exportAnalysePlanInfo(params).then((data) => {
        handleExportFile(data, '导出分析预案.xls');
      });
    },
    toggleAction(e, records) {
      if(!this.hasPermission(1092121310)) {
        this.permissionWarning();
        return;
      }
      records.planStatus = e ? 1 : 0;
      // this.modifyAnStatus(records.schemeId);
      let params = {
        planId: records.planId,
        planStatus: records.planStatus,
        userId: this.userInfo.userId,
      };
      this.$api.aiCenterY.startOrStopPlan(params).then((res) => {
        if (res.code === CODE_OK) {
          this.$message.success(`${e ? '启用' : '禁止'}成功！`);
        }
      });
    },

    delHandle(records) {
      if(!this.hasPermission(1092121313)) {
        this.permissionWarning();
        return;
      }
      this.$confirm({
        title: '提示',
        content: '你确定删除该数据吗?',
        onOk: () => {
          /* this.deleteAnaStatus(records.schemeId).then((res) => {
            if (res.code === 200) {
              this._getAnalyze();
            }
          }); */
          this.$api.aiCenterY.deleteTask(records.planId).then((res) => {
            if (res.code === CODE_OK) {
              this.$message.success('删除该数据成功！');
              this.searchHandle();
            } else {
              this.$message.error('删除该数据失败！');
            }
          });
        },
        onCancel: () => {},
      });
    },
    addHandle() {
       if(!this.hasPermission(1092121311)) {
        this.permissionWarning();
        return;
      }
      this.addVisible = true;
      this.isEdit = false;
    },
    editHandle(records) {
      this.addVisible = true;
      this.isEdit = true;
      this.editData = records;
      // console.error('haha', this.editData);
    },
    onChangeFilter(e) {
      this.checkboxValue = e;
      /*  console.error(this.checkboxValue, e); */
      // this.setChecked();
    },
    viewHandle(record) {
      if (!record.executeCount) {
        return;
      }
      this.visibleCount = true;
      this.planId = record.planId;
      // console.error(record);
    },
    closeHandle() {
      this.visibleCount = false;
      this.planId = '';
    },
    closeAnalyzed() {
      this.addVisible = false;
      this.isEdit = false;
      // this._getAnalyze();
    },
    sureHandle() {
      this.closeAnalyzed();
      this.searchHandle();
    },
    handleChangeStatus() {},
    ...mapActions([
      'getCameraGroupCondition',
      'modifyAnStatus',
      'deleteAnaStatus',
    ]),
  },
};
</script>

<style scoped>
.icon-g {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
</style>
