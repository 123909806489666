<template>
  <div>
    <a-modal
      :title="isEdit ? '编辑预案' : '新增预案'"
      :visible="visible"
      :confirm-loading="confirmLoading"
      :centered="true"
      :dialogStyle="dialogStyle"
      @ok="handleOk"
      width="50%"
      @cancel="handleCancel"
    >
      <vue-scroll :ops="$root.scrollOpsY" style="height: 450px; width: 102%">
        <div class="m-b-sm flex items-center">
          <span class="text-white wd-title">方案名称：</span
          ><a-input
            placeholder="请输入方案名称"
            class="search-mx-wd-7"
            v-model="addParams.schemaName"
          />
        </div>
        <div class="m-b-sm flex">
          <span class="text-white wd-title">选择分析事件：</span>
          <div class="flex-one">
            <div
              v-for="(item, index) in addParams.analyzeEvent"
              :key="item.id"
              class="flex"
            >
              <div class="wd-check">
                <!-- 父选择框 -->
                <a-checkbox
                  :indeterminate="item.indeterminate"
                  v-model="item.checked"
                  @change="(e) => onCheckEventTotal(e, index)"
                >
                  {{ item.eventName }}
                </a-checkbox>
              </div>
              <div class="flex-one">
                <a-checkbox-group
                  v-model="item.checkGroupValue"
                  @change="(e) => onChangeSingle(e, index)"
                  class="full-width bg-f p-check-xs"
                >
                  <a-row>
                    <a-col
                      :span="12"
                      v-for="child in item.childList"
                      :key="child.id"
                    >
                      <!-- 子选择框 -->
                      <a-checkbox :value="child.id">
                        {{ child.eventName }}
                      </a-checkbox>
                    </a-col>
                  </a-row>
                </a-checkbox-group>
              </div>
            </div>
          </div>
        </div>
        <div class="m-b-sm flex">
          <span class="text-white wd-title">选择摄像机：</span>
          <span class="text-lightblue flex items-center"
            ><!-- <i class="r-add m-r-sm" @click="addHandle"></i> --><a-icon
              type="plus-circle"
              class="m-r-sm"
              @click="addHandle"
            />已选
            <span class="text-line m-l-xs m-r-xs">{{
              selectCameraList.length
            }}</span>
            路摄像机</span
          >
        </div>
        <div class="m-b-sm flex items-center">
          <span class="text-white wd-title">选择摄像机组：</span>
          <a-select
            v-model="addParams.turnContent"
            mode="multiple"
            placeholder="请选择摄像机组"
            class="search-mx-wd-7"
            @change="handleTurnContentChange"
          >
            <a-select-option
              v-for="item in cameraGroupLists"
              :value="item.groupId"
              :key="item.groupId"
            >
              {{ item.groupName }}
            </a-select-option>
          </a-select>
        </div>
        <div class="m-b-sm flex">
          <span class="text-white wd-title">分析方案：</span>
          <div class="flex-one">
            <div class="m-b-sm full-width">
              <a-radio-group
                v-model="addParams.analyzeSchema.type"
                @change="onChangeSchema"
                class="full-width"
              >
                <a-radio :value="0" class="full-width m-b-xs">
                  立即循环分析
                </a-radio>
                <a-radio :value="1" class="full-width m-b-xs">
                  按时间段定时巡检
                  <a-range-picker
                    style="width: 200px"
                    class="m-l-sm m-r-sm"
                    v-model="addParams.analyzeSchema.eventDate"
                    @change="onChangeDate"
                    :disabledDate="disabledDate"
                  >
                  </a-range-picker>
                  <a-time-picker
                    use24-hours
                    style="width: 120px"
                    v-model="addParams.analyzeSchema.eventDateTime"
                    @change="onChangeTime"
                  />
                </a-radio>
                <a-radio :value="2" class="full-width m-b-xs">
                  每日定时巡检
                  <a-time-picker
                    use24-hours
                    style="width: 120px"
                    class="m-l-sm"
                    placeholder="开始时间"
                    v-model="addParams.analyzeSchema.eventConfStartTime"
                    @change="onChangeConfStartTime"
                  />
                  <span class="m-l-sm">~</span>
                  <a-time-picker
                    use24-hours
                    style="width: 120px"
                    class="m-l-sm"
                    placeholder="结束时间"
                    v-model="addParams.analyzeSchema.eventConfEndTime"
                    @change="onChangeConfEndTime"
                  />
                </a-radio>
              </a-radio-group>
            </div>
            <!-- <div class="full-width" v-show="false">
              <a-checkbox
                v-model="addParams.analyzeResource"
                @change="onCheckEvent"
              >
                分析资源优先
              </a-checkbox>
              <span class="text-lightblue"
                >优先<a-input-number
                  v-model="addParams.analyseLu"
                  style="width: 80px"
                  class="m-l-xs m-r-xs"
                />路</span
              >
            </div> -->
          </div>
        </div>
        <div class="m-b-sm flex">
          <span class="text-white wd-title">启用状态：</span>
          <div class="flex-one">
            <a-radio-group v-model="addParams.status" @change="changeStatus">
              <a-radio :value="1"> 启用 </a-radio>
              <a-radio :value="0"> 停用 </a-radio>
            </a-radio-group>
          </div>
        </div>
      </vue-scroll>
    </a-modal>
    <cameral-select
      :visible="cameraSelectVisible"
      @closeCameralDialog="closeCameralDialogHandle"
    />
  </div>
</template>
<script>
/**
 * CameralSelect()引入相机选择
 */
import CameralSelect from '@/components/CameralSelect';
import { mapState, mapActions, mapMutations } from 'vuex';
import moment from 'moment';
export default {
  name: 'TopRightAnalyzedDialog',
  props: {
    visible: {
      type: Boolean,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    editData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      mode2: ['time', 'time'],
      dialogStyle: { maxWidth: '700px' },
      cameraSelectVisible: false,
      confirmLoading: false,
      addParams: {
        schemaName: '',
        analyzeEvent: [],
        analyzeSchema: {
          type: 0,
          eventDate: null,
          eventDateTime: null,
          eventConfStartTime: null,
          eventConfEndTime: null,
        },
        // analyseLu: undefined,
        status: 1,
        turnContent: undefined,
        // analyzeResource: false,
      },
      cameraGroupLists: [],
      disabledDate: (currentDate) => {
        return currentDate < moment().subtract(1, 'day');
      },
    };
  },
  computed: {
    /* eventDistFormat() {
      return this.eventDist.map((item) => {
        this.$set(item, 'check', false);
        return item;
      });
    }, */
    ...mapState({
      selectCameraList: (state) => state.collect.selectCameraList,
      eventDist: (state) => state.aiEvent.eventDist,
      // cameraGroupLists: (state) => state.resource.cameraGroupLists,
    }),
  },
  components: {
    CameralSelect,
  },
  created() {
    this.getEventDict();
  },
  mounted() {},
  // 监听事件
  watch: {
    eventDist(newValue) {
      this._getDefaultEventDict(newValue);
    },
    /* editData: {
      handler() {
        console.error(111111111111);
        this._initDefaultData();
      },
      deep: true,
    }, */
    isEdit(newValue) {
      if (newValue) {
        this._initDefaultData();
        this._getDefaultEventDict(this.eventDist);
      }
    },
    visible(nv, ov) {
      if (nv && nv !== ov) {
        this._getCameraGroupLists();
      }
    },
  },
  methods: {
    ...mapActions(['getEventDict']),
    ...mapMutations(['setCurrentComIndex', 'setSelectCameraList']),
    _getCameraGroupLists() {
      let obj = {
        currPage: 1,
        pageSize: 20,
        /*    groupName: this.cameraGroupName,
        createUser: this.cameraUser, */
      };
      this.$api.cameraGroup.getGroupList(obj).then((res) => {
        // console.error(res);
        if (res.code === 200) {
          this.cameraGroupLists = res.data;
        }
      });
    },
    _getDefaultEventDict(newValue) {
      this.addParams.analyzeEvent = newValue.map((item) => {
        if (!this.isEdit) {
          this.$set(item, 'checked', false);
          this.$set(item, 'indeterminate', false);
          this.$set(item, 'checkGroupValue', []);
        } else {
          let aid = this.editData.aiId;
          /* aid.forEach((aidItem) => {
            if (aidItem.parentId === item.id) {
              this.$set(item, 'checked', true);
              this.$set(item, 'checkGroupValue', [aidItem.childId]);
            }
          }); */

          //编辑这块有问题
          for (let i = 0; i < aid.length; i++) {
            if (aid[i].parentId === item.id) {
              this.$set(item, 'checked', true);
              // this.$set(item,'indeterminate',true)
              this.$set(item, 'checkGroupValue', [aid[i].childId]);
              break;
            }
          }
        }
        return item;
      });
      // console.error(this.addParams.analyzeEvent);
    },
    _initDefaultData() {
      /*  addParams: {
        schemaName: '',
        analyzeEvent: [],
        analyzeSchema: {
          type: 0,
          eventDate: null,
          eventDateTime: null,
          eventConfTime: null,
        },
        analyseLu: undefined,
        status: 0,
        turnContent: undefined,
      }, */
      this.addParams.schemaName = this.editData.schemeName;
      this.addParams.analyzeSchema.type = this.editData.analyseType;
      // this.addParams.analyzeResource = this.editData.isAnalyse;
      // this.addParams.analyseLu = this.editData.analyseLu;
      this.addParams.status = this.editData.schemeStatus;
    },
    onCheckEventTotal(e, index) {
      // console.error('haha', e.target.checked);  //true
      let check = e.target.checked;
      let curEvent = this.addParams['analyzeEvent'][index];
      let checkGroupId = curEvent['childList'].map((item) => item.id);
      this.$set(curEvent, 'checked', check);
      if (!e.target.checked) {
        this.$set(curEvent, 'checkGroupValue', []);
      } else {
        this.$set(curEvent, 'checkGroupValue', checkGroupId);
      }
    },
    onChangeSingle(e, index) {
      // console.error('hhah', e);
      // console.error(this.addParams['analyzeEvent'][index]);
      let curEvent = this.addParams['analyzeEvent'][index];
      this.$set(
        curEvent,
        'indeterminate',
        e.length != 0 && e.length < curEvent['childList'].length
      );
      this.$set(curEvent, 'checked', e.length == curEvent['childList'].length);
      this.$set(curEvent, 'checkGroupValue', e);
    },
    onChangeSchema(e) {
      let val = e.target.value;
      this.$set(this.addParams['analyzeSchema'], 'type', val);
      if (val === 2) {
        this.$set(this.addParams['analyzeSchema'], 'eventDate', null);
        this.$set(this.addParams['analyzeSchema'], 'eventDateTime', null);
      } else if (val === 1) {
        this.$set(this.addParams['analyzeSchema'], 'eventConfStartTime', null);
        this.$set(this.addParams['analyzeSchema'], 'eventConfEndTime', null);
      } else {
        this.$set(this.addParams['analyzeSchema'], 'eventDate', null);
        this.$set(this.addParams['analyzeSchema'], 'eventDateTime', null);
        this.$set(this.addParams['analyzeSchema'], 'eventConfStartTime', null);
        this.$set(this.addParams['analyzeSchema'], 'eventConfEndTime', null);
      }
    },
    onChangeDate(date) {
      this.$set(this.addParams['analyzeSchema'], 'eventDate', date);
    },
    onChangeTime(time) {
      this.$set(this.addParams['analyzeSchema'], 'eventDateTime', time);
    },
    onChangeConfStartTime(time) {
      this.$set(this.addParams['analyzeSchema'], 'eventConfStartTime', time);
    },
    onChangeConfEndTime(time) {
      this.$set(this.addParams['analyzeSchema'], 'eventConfEndTime', time);
    },
    // onCheckEvent(e) {
    //   this.analyzeResource = e.target.value;
    // },
    changeStatus(e) {
      this.$set(this.addParams, 'status', e.target.value);
    },
    handleCancel() {
      this.resetData();
      this.$emit('close');
    },
    resetData() {
      this.addParams.schemaName = '';
      this.addParams.analyzeSchema.type = 0;
      this.addParams.analyzeSchema.eventDate = null;
      this.addParams.analyzeSchema.eventDateTime = null;
      this.addParams.analyzeSchema.eventConfStartTime = null;
      this.addParams.analyzeSchema.eventConfEndTime = null;
      // this.addParams.analyseLu = undefined;
      this.addParams.status = 1;
      this.addParams.turnContent = undefined;
      this.addParams.analyzeEvent = this.addParams.analyzeEvent.map((item) => {
        item.checked = false;
        item.indeterminate = false;
        item.checkGroupValue = [];
        return item;
      });
      // this.addParams.analyzeResource = false;
      this.setSelectCameraList([]);
    },
    handleOk() {
      let aid = [];
      this.addParams.analyzeEvent.forEach((item) => {
        /* if (item.checked) {
          aid.push(item.id);
          aid = aid.concat(item.checkGroupValue);
        } */
        item.checkGroupValue.forEach((cg) => {
          aid.push(cg);
        });
      });

      let beginDate, endDate;
      if (this.addParams.analyzeSchema.type === 2) {
        beginDate = this.addParams.analyzeSchema.eventConfStartTime
          ? moment(this.addParams.analyzeSchema.eventConfStartTime).format(
              'HH:mm:ss'
            )
          : '';
        endDate = this.addParams.analyzeSchema.eventConfEndTime
          ? moment(this.addParams.analyzeSchema.eventConfEndTime).format(
              'HH:mm:ss'
            )
          : '';
      } else if (this.addParams.analyzeSchema.type === 1) {
        beginDate =
          this.addParams.analyzeSchema.eventDate &&
          this.addParams.analyzeSchema.eventDateTime
            ? moment(this.addParams.analyzeSchema.eventDate[0]).format(
                'YYYY-MM-DD'
              ) +
              ' ' +
              moment(this.addParams.analyzeSchema.eventDateTime).format(
                'HH:mm:ss'
              )
            : '';
        endDate =
          this.addParams.analyzeSchema.eventDate &&
          this.addParams.analyzeSchema.eventDateTime
            ? moment(this.addParams.analyzeSchema.eventDate[1]).format(
                'YYYY-MM-DD'
              ) +
              ' ' +
              moment(this.addParams.analyzeSchema.eventDateTime).format(
                'HH:mm:ss'
              )
            : '';
      }

      /*  let executeDate =
        this.addParams.analyzeSchema.type === 2 &&
        this.addParams.analyzeSchema.eventConfTime
          ? moment().format('YYYY-MM-DD') +
            ' ' +
            moment(this.addParams.analyzeSchema.eventConfTime[1]).format(
              'HH:mm:ss'
            )
          : ''; */
      let data = {
        aiId: aid,
        /* analyseLu: this.addParams.analyzeResource
          ? this.addParams.analyseLu
          : '', */
        // isAnalyse: this.addParams.analyzeResource ? 1 : 0,
        /*  analyseType: this.addParams.analyzeResource
          ? 3
          : this.addParams.analyzeSchema.type, */
        analyseType: this.addParams.analyzeSchema.type,
        beginDate,
        cameraNum: this.selectCameraList.map((item) => item.cameraNum),
        cameraSchemeGroupId: this.addParams.turnContent,
        endDate,
        // executeDate,
        planName: this.addParams.schemaName.trim(),
        planStatus: this.addParams.status,
      };
      if (!data.planName) {
        this.$message.error('请填写方案名称');
        return;
      }
      if (data.aiId.length <= 0) {
        this.$message.error('请选择分析事件');
        return;
      }
      if (data.cameraNum.length <= 0) {
        this.$message.error('请选择摄像机');
        return;
      }

      if (
        this.addParams.analyzeSchema.type === 1 ||
        this.addParams.analyzeSchema.type === 2
      ) {
        if (!data.beginDate && !data.endDate) {
          this.$message.error('请选择时间');
          return;
        }
      }
      if (this.addParams.analyzeSchema.type === 1) {
        if (data.beginDate === data.endDate) {
          this.$message.error('按时间段定时轮巡不能选择同一天');
          return;
        }
      }
      /*  console.error('sxy----', this.addParams, data); */
      if (!this.isEdit) {
        let objParams = {
          data,
          instructionsDel: {
            module: 'AI分析预案',
            page: '',
            feature: '新增分析预案',
            description: data.planName,
          },
        };
        this.$api.aiCenterY.addTask(objParams).then((res) => {
          if (res.code === 200) {
            this.$message.success('新增分析预案成功');
            this.resetData();
            this.$emit('sure');
          }
        });
      } else {
        this.$api.aiCenterY
          .updateAnalyse(this.editData.schemeId, data)
          .then((res) => {
            if (res.code === 200) {
              this.$message.success('编辑分析预案成功');
              this.resetData();
              this.$emit('close');
            }
          });
      }
      // this.$emit('close');
    },
    closeCameralDialogHandle() {
      this.cameraSelectVisible = false;
    },
    addHandle() {
      this.cameraSelectVisible = true;
      //设置初始状态值
      this.setCurrentComIndex(0);
    },
    handleTurnContentChange(val) {
      this.addParams.turnContent = val;
    },
  },
};
</script>

<style scoped>
.wd-title {
  width: 100px;
}
.wd-check {
  width: 120px;
}
.p-check-xs {
  padding: 5px 10px;
}
.dialog-container >>> .ant-modal-content {
  max-width: 200px;
}
</style>
