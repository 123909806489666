<template>
  <div>
    <a-modal
      title="分析次数及结果"
      :visible="visible"
      :centered="true"
      width="98%"
      :footer="null"
      @cancel="handleCancel"
    >
      <!--  <vue-scroll :ops="$root.scrollOpsY" style="height:400px"> -->
      <a-table
        :columns="columns"
        :rowKey="(record, index) => index"
        :components="resibleTableHeader"
        :data-source="dataList"
        :pagination="false"
        :scroll="scroll"
        :loading="loading"
        bordered
      >
        <!--  <template :slot="record.key" slot-scope="text, record">
            <span class="text-red">{{ text }}</span>
          </template> -->
      </a-table>
      <!--  </vue-scroll> -->
    </a-modal>
  </div>
</template>

<script>
import { CODE_OK } from '@/request/config_code';
import ResizableTable from '@/components/mixins/resizeableTable';
export default {
  name: 'analyzeCountResult',
  mixins:[ResizableTable],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    taskId: {
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      columns: [
        //一个对象是一列
        {
          title: '序号',
          dataIndex:'resultIndex',
          customRender: (text, record, index) => index + 1,
        },
        {
          title: '归属路线',
          dataIndex: 'roadCode',
          key: 'roadCode',
          ellipsis: true,
          width:100,
        },
        {
          title: '摄像机名称',
          dataIndex: 'cameraName',
          key: 'cameraName',
          ellipsis: true,
          width:180
        },
        {
          title: '桩号',
          dataIndex: 'pile',
          key: 'pile',
          ellipsis: true,
          width:120
        },
        {
          title: '截图分析事件结果',
          dataIndex: 'result',
          key: 'result',
          children: [
            /*  {
              title: '缓行',
              dataIndex: 'walk',
              key: 'walk',
            },
            {
              title: '停车',
              dataIndex: 'stop',
              key: 'stop',
            },
            {
              title: '撞车',
              dataIndex: 'car',
              key: 'car',
            },
            {
              title: '逆行',
              dataIndex: 'lWalk',
              key: 'lWalk',
            }, */
          ],
        },
      ],
      dataList: [
        /*  {
          id: 0,
          cameraName: '摄像机名称',
          cameraNum: '7486153165',
          reportTime: '2020-4-9 20:16:28',
          walk: '无',
          stop: '无',
          car: '无',
          lWalk: '无',
        }, */
      ],
      scroll: {
        y: window.innerHeight > 768 ? 660 : window.innerHeight - 360,
        /* x: window.innerWidth + 2200, */
        x:'100%'
      },
      loading: false,
    };
  },
  computed: {},
  watch: {
    taskId(nv, ov) {
      if (nv && nv != ov) {
        this._getCountResult();
      }
    },
  },
  methods: {
    handleCancel() {
      this.$emit('close');
    },
    _getCountResult() {
      this.loading = true;
      Promise.all([
        this._queryCameraEventByTaskId(),
        this._queryEventTypeListByTaskId(),
      ]).then((res) => {
        let arr1 = res[0];
        let arr2 = res[1];
        this.columns[4].children = arr2.map((item) => {
          return {
            title: item.name,
            dataIndex: item.key,
            key: item.key,
            ellipsis: true,
            width:200,
            scopedSlots: { customRender: item.key },
          };
        });

        this.dataList = arr1.map((item) => {
          arr2.forEach((item2, index) => {
            item[item2.key] = item['eventList']
              ? item['eventList'][index].isExist
              : '无';
          });
          return item;
        });
        this.loading = false;
      });
    },
    _queryCameraEventByTaskId() {
      return this.$api.aiCenterY
        .queryCameraEventByTaskId(this.taskId)
        .then((res) => {
          if (res.code == CODE_OK) {
            return res.data;
          }
        });
    },
    _queryEventTypeListByTaskId() {
      return this.$api.aiCenterY
        .queryEventTypeListByTaskId(this.taskId)
        .then((res) => {
          if (res.code == CODE_OK) {
            return res.data;
          }
        });
    },
  },
};
</script>

<style scoped></style>
